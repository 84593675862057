<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!spotCategory">
      <span class="loader"></span>
    </span>
    <spot-category-form
      v-if="spotCategory"
      :loading="loading"
      :spotCategoryData="spotCategory"
      :formErrors="formErrors"
      @spotCategorySubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseSpotCategoryModal="onCloseSpotCategoryModal"
    />
  </div>
</template>

<script>
import SpotCategoryForm from "../partials/SpotCategoryForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { SpotCategoryForm },

  mixins: [alertLeave],

  props: {
    spotCategoryId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      spotCategory: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("spotCategories/get", this.spotCategoryId);
        this.spotCategory = this.$store.getters["spotCategories/spotCategory"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(spotCategory) {
      this.loading = true;
      const spotCategoryData = cloneDeep(spotCategory);

      try {
        await this.$store.dispatch("spotCategories/update", spotCategoryData);
        this.$notify({
          type: "success",
          message: this.$t("SPOT_CATEGORIES.SPOT_CATEGORY_UPDATED"),
        });
        const spotCategory = await this.$store.getters[
          "spotCategories/spotCategory"
        ];
        this.$emit("onViewSpotCategory", spotCategory, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    onCloseSpotCategoryModal() {
      this.$emit("onViewSpotCategory");
    },
  },
};
</script>
