export default {
  type: "spot-categories",
  name: null,
  excerpt: "",
  color: null,
  icon: null,
  form_type: 'DEFAULT',
  created_at: null,
  relationshipNames: [
    "parent",
  ],
  parent: {
    type: "spot-categories",
    id: null,
  },
};
